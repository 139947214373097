import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';

import { Client } from '@/generated/core.graphql';

interface ITenantContext {
  client?: Client;
  setClientId: (clientId: string) => void;
  removeClientId: () => void;
}

const TenantContext = React.createContext<ITenantContext>({
  setClientId: () => null,
  removeClientId: () => null,
});

function TenantProvider({ children }: { children: React.ReactNode }) {
  const [selectedClientId, setSelectedClientId] = useState('');

  React.useEffect(() => {
    const clientId =
      typeof localStorage !== 'undefined' && localStorage?.getItem('clientId');
    if (clientId) {
      setSelectedClientId(clientId);
    }
  }, []);

  const setClientId = useCallback((id) => {
    localStorage.setItem('clientId', id);
    setSelectedClientId(id);
  }, []);

  const removeClientId = useCallback(() => {
    localStorage.removeItem('clientId');
    setSelectedClientId('');
  }, []);

  const value = useMemo(() => {
    return {
      selectedClientId,
      setClientId,
      removeClientId,
    };
  }, [setClientId, removeClientId, selectedClientId]);

  return (
    <TenantContext.Provider value={value}>{children}</TenantContext.Provider>
  );
}

function useTenant() {
  const context = React.useContext(TenantContext);
  if (context === undefined) {
    throw new Error('useTenant must be used within a TenantProvider.');
  }
  return context;
}

export { TenantProvider, useTenant };
